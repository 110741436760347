.philosophy {
  box-sizing: border-box;
  width: 100%;
  color: var(--blue);
}

.philosophy > p {
  width: clamp(300px, 80vw, 850px);
  margin: 20px auto;
  /* width: 600px; */
  text-align: center;
  font-size: large;
  line-height: 1.5;
}

#equipment-blurb-container {
  color: white;
  background-color: var(--blue);
  width: 100%;
  text-align: center;
  padding: 5px
}

.equipment-blurb {
  line-height: 1.3;
  font-size: x-large;
  width: max(340px, 70%);
  margin: auto;
}

#first-sentence {
  padding-top: 3.5vw;
  padding-bottom: max(25px, 1.5vw);
}

#second-sentence {
  padding-bottom: 3.5vw;
}
