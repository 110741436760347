.treatments-container {
  margin: 40px auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.treatment {
  color: #00163f;
  text-align: center;
  margin: 20px;
  margin-bottom: 0px;
  flex: 0 1 300px;
  font-size: x-large;
}

.treatment-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

#offered-programs-list-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: left;
}

.offered-programs-list {
  display: flex;
  flex: 1 1 200px;
}

.hidden {
  display: none;
}

.active {
  background-color: var(--green);
  color: white;
  border-radius: 5px;
}