
.blog-page {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap-reverse;
    width: 100vw;
}

.sidebar {
    width: min(95vw, 300px);
    margin-bottom: 20px;
}

.header {
    text-align: center;
}

.blog-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 60vw;
    padding-bottom: 30px;
    border-bottom: 5px solid var(--blue);
}

.article-preview > h2, .article-link {
    color: var(--blue);
}

.article-preview {
    width: 75%;
}

#blog-image {
    width: 200px;
}

@media screen and (max-width: 768px) {
    .article-preview {
        width: 100%;
    }
    .blog-container, #blog-image {
        width: 85vw;
    }
}

#categories-header {
    text-align: center;
}

.blog-header {
    padding: 0px 40px;
    box-sizing: border-box;
    color: var(--blue);
}

.blog-header > h1 {
    padding-top: 20px;
    background-color: var(--grey);
    width: max(450px, 90%);
    margin: 0 auto;
    font-weight: 200;
    /* font-style: italic; */
    text-align: center;
    font-size: clamp(32px, 4vw, 36px);
    text-decoration: "none"
}

.blog-header > h2 {
    padding: 20px 0px;
    background-color: var(--grey);
    width: max(450px, 90%);
    margin: 0 auto;
    font-weight: 200;
    /* font-style: italic; */
    text-align: center;
    border-radius: 0 0 15px 15px;
    font-size: clamp(28px, 3.5vw, 30px);
    text-decoration: "none"
}
