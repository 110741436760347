.schedule-appointment {
  padding: 40px 20px 20px 20px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  color: var(--blue);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.goal {
  font-size: max(1.4vw, 18px);
  font-weight: bold;
  flex: 0 1 500px;
  text-align: center;
}
