.home-welcome {
    padding: 0px 40px;
    box-sizing: border-box;
    color: var(--blue);
    margin-bottom: 30px;
}

.home-welcome > h1 {
    margin: 0px 10px;
    padding: 20px 0px;
    background-color: var(--grey);
    width: max(450px, 90%);
    margin: 0 auto;
    font-weight: 200;
    /* font-style: italic; */
    text-align: center;
    border-radius: 0 0 15px 15px;
    font-size: clamp(32px, 4vw, 36px);
    text-decoration: "none"
}